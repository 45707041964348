/*------- 14. Brand logo style  -------*/
@mixin respond-to($breakpoint) {
  @if $breakpoint == mobile {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $breakpoint == tablet {
    @media (min-width: 768px) and (max-width: 1024px) {
      @content;
    }
  } @else if $breakpoint == desktop {
    @media (min-width: 1025px) {
      @content;
    }
  }
}

.modal-body img {
  max-width: 65%; /* Default for larger screens */
  height: auto;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

/* Adjust for mobile screens */
@include respond-to(mobile) {
  .modal-body img {
    max-width: 105%;
  }
}

/* Adjust for tablet screens */
@include respond-to(tablet) {
  .modal-body img {
    max-width: 105%;
  }
}

.brand-logo-active,
.brand-logo-active-2 {
  &.owl-carousel {
    .owl-item {
      img {
        display: inline-block;
        width: auto;
      }
    }
  }
  .single-brand-logo,
  .single-brand-logo2 {
    text-align: center;
    img {
      transition: all 0.5s ease 0s;
    }

  }
}

.brand-logo-wrap {
  padding: 100px 90px;
  @media #{$xs-layout} {
    padding: 50px 20px;
  }
}
