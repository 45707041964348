/* Remove the focus outline for checkboxes and radio buttons */
@mixin respond-to($breakpoint) {
  @if $breakpoint == mobile {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $breakpoint == tablet {
    @media (min-width: 768px) and (max-width: 1024px) {
      @content;
    }
  } @else if $breakpoint == desktop {
    @media (min-width: 1025px) {
      @content;
    }
  }
}

.temtemp {
  @include respond-to(mobile) {
    padding: 15px !important; /* No padding for mobile */
  }

  @include respond-to(desktop) {
    padding: 60px !important;
  }

  @include respond-to(tablet) {
    padding: 60px !important;
  }
}

.modal-top .modal-dialog {
  @include respond-to(desktop) {
    top: -10%; /* Adjust the top spacing as needed */
    transform: none; /* Remove centering transform */
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px; /* Match the height of the iframe for proper alignment */
}

.bottomStyleMargin {
  @include respond-to(mobile) {
    margin-bottom: 20px !important;
  }

  @include respond-to(desktop) {
    margin-bottom: 10px !important;
  }
  @include respond-to(tablet) {
    margin-bottom: 15px !important;
  }
}

.minHeight2 {
  @include respond-to(mobile) {
    min-height: 290px !important;
  }

  @include respond-to(desktop) {
    min-height: 250px !important;
  }
  @include respond-to(tablet) {
    min-height: 280px !important;
  }
}

.minHeight3 {
  @include respond-to(mobile) {
    min-height: 290px !important;
  }

  @include respond-to(desktop) {
    min-height: 290px !important;
  }
  @include respond-to(tablet) {
    min-height: 308px !important;
  }
}
