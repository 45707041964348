@mixin respond-to($breakpoint) {
  @if $breakpoint == mobile {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $breakpoint == tablet {
    @media (min-width: 768px) and (max-width: 1024px) {
      @content;
    }
  } @else if $breakpoint == desktop {
    @media (min-width: 1025px) {
      @content;
    }
  }
}

.tempClassClass {
  @include respond-to(mobile) {
    margin-right: 0; /* No margin for mobile */
  }

  @include respond-to(desktop) {
    margin-right: 20px; /* Add margin for desktop view */
  }

  @include respond-to(tablet) {
    margin-right: 20px; /* Adjust margin for tablet view if needed */
  }
}


.row {
  @include respond-to(mobile) {
    margin-right: 0 !important; /* No margin for mobile */
    margin-left: 0 !important;
    padding: 0 !important;
  }
}
