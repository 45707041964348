@mixin respond-to($breakpoint) {
  @if $breakpoint==mobile {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $breakpoint==tablet {
    @media (min-width: 768px) and (max-width: 1024px) {
      @content;
    }
  } @else if $breakpoint==desktop {
    @media (min-width: 1025px) {
      @content;
    }
  }
}

.testimonials-container {
  margin-top: 2rem;
  padding: 2rem;
  color: black;
  // Add desktop-specific padding
  @include respond-to(desktop) {
    padding-left: 400px;
    padding-right: 400px;
  }
  h2 {
    color: black;
  }
  .testimonial-card {
    background-color: transparent;
    border: none;
    padding: 1rem;
    margin: 1rem;
    max-width: 300px;
    .quote {
      font-size: 1rem;
      font-style: italic;
      color: black;
    }
    .author {
      font-size: 0.9rem;
      font-weight: bold;
      margin-top: 0.5rem;
      color: black; // Gold color for author name
    }
  }
}
