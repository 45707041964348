.floating-cart-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #ffc107;
    /* Warning color */
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    z-index: 1000;
    /* Ensure it floats above other elements */
    transition: all 0.3s ease;
}

.floating-cart-btn:hover {
    transform: scale(1.1);
    background-color: #e0a800;
    /* Darker warning color for hover */
}

.cart-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    width: 500px;
    max-width: 90%;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
}

.cart-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.cart-modal h2 {
    text-align: center;
    margin-bottom: 20px;
}

.close-modal-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.cart-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.cart-table th,
.cart-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.cart-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.remove-item-btn {
    background: #dc3545;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
}

.remove-item-btn:hover {
    opacity: 0.8;
}

.email-cart-btn,
.text-cart-btn,
.close-modal-btn-secondary {
    background: #e07431;
    color: #fff;
    border: none;
    font-size: 12px;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 10px;
}

.email-cart-btn:hover,
.text-cart-btn:hover,
.close-modal-btn-secondary:hover {
    opacity: 0.8;
}