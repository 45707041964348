.image-container {
  position: relative;
  display: inline-block;
  cursor: pointer;

  img {
    transition: transform 0.3s ease-in-out;
  }

  &:hover img {
    transform: scale(1.05); // Slight zoom on hover
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    font-size: 16px;
    font-weight: bold;
    transition: opacity 0.3s ease-in-out;
    border-radius: 0.25rem;
  }

  &:hover .overlay {
    opacity: 1; // Fade in overlay on hover
  }
}

.fullscreen-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;

  .fullscreen-image {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    border-radius: 8px;
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1100;
    border: none;
    font-size: 16px;
    padding: 8px 12px;
    cursor: pointer;

  }
}

.highlight {
  background: #ffa56a;
}