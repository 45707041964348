.main-menu ul {
    display: flex;
    gap: 20px;
    list-style: none;
    padding: 0;
    margin: 0;
    // margin-top: 10px !important;
  }
  
  .main-menu ul li {
    position: relative;
  }
  
  .main-menu ul li a {
    text-decoration: none;
    color: black;
    font-size: 16px;
    line-height: 55px !important;
    position: relative;
    padding: 5px 10px;
    transition: color 0.3s ease;
  }
  
  .main-menu ul li a::after {
    content: "";
    position: absolute;
    bottom: -2px; /* Adjusted to bring the underline closer */
    left: 0;
    width: 100%;
    height: 2px; /* Reduced thickness for a compact look */
    background: transparent;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  .main-menu ul li a:hover::after,
  .main-menu ul li.active a::after {
    transform: scaleX(1);
    background-color: orange; /* Active wave color */
  }
  
  .main-menu ul li a:hover {
    color: black; /* Hover text color */
  }
  
  .main-menu ul li.active a {
    color: orange; /* Active link text color */
  }
  